import React from 'react';
import { Navigation } from '@boxt/design-system';
import { useFsFlag } from '@flagship.io/react-sdk';
import PropTypes from 'prop-types';

import PlaybackWidget from 'Features/PlaybackWidget';
import { sendToCs } from 'Helpers';
import { useChat } from 'Hooks/useChat';

const Nav = ({ hasLoaded }) => {
  const isHeatPumpFeatureFlagActive = useFsFlag('heat-pumps-in-main-nav', false).getValue();
  const isPlaybackWidgetEnabled = useFsFlag('quote_playback_widget', false).getValue();
  const isPolicyIntegrationFlagActive = useFsFlag('policy-integration', false).getValue();
  const widgetData = localStorage.getItem('playbackWidgetData');
  const { hide, openChat, minimize } = useChat();

  if (!hasLoaded) {
    return null;
  }

  if (widgetData) {
    sendToCs('AB_ABT_FS_quote_playback_widget', isPlaybackWidgetEnabled ? 'Variant 1' : 'Original');
  }

  return (
    <>
      <Navigation
        openLiveChat={openChat}
        hideLiveChat={hide}
        minimizeLiveChat={minimize}
        isHeatPumpFeatureFlagActive={isHeatPumpFeatureFlagActive}
        isPolicyIntegrationFlagActive={isPolicyIntegrationFlagActive}
      />
      {isPlaybackWidgetEnabled && widgetData && <PlaybackWidget />}
    </>
  );
};

Nav.propTypes = {
  hasLoaded: PropTypes.bool,
};

Nav.defaultProps = {
  hasLoaded: false,
};

export default Nav;
